<div *ngFor="let list of blogList" class="iq-blog-box">
    <div class="iq-blog-image clearfix">
        <img src="{{ list.image }}" class="img-fluid center-block" alt="blogimage1">
        <div class="iq-blog-detail">
            <div class="iq-blog-meta">
                <ul>
                    <li class="list-inline-item">
                        <span class="screen-reader-text">Posted on</span> <a href="#" rel="bookmark">{{ list.datetime }}</a>
                    </li>
                </ul>
            </div>
            <div class="blog-title">
                <a href="#">
                    <h5 class="mb-2">{{ list.title }}</h5>
                </a>
            </div>
            <p class="mb-0">{{ list.description }}</p>
        </div>
    </div>
</div>