<footer id="iq-contact" class="position-relative  footer-two p-0">
    <div class="container">
        <div class="row justify-content-center footer-subscribe">
            <div class="col-lg-8 footer-box">
                <div class="footer-input">
                    <input type="Email" class="form-control m-0" id="inputEmail" placeholder="Your Email">
                    <a class="button sign-btn org-btn" href="">Sign up</a>
                </div>
            </div>
            <div class="col-lg-12 text-center">
                <div class="widget footer-logo text-center">
                    <div class="textwidget">
                        <p><img class="alignnone size-full wp-image-2089 m-0" src="{{ footerLogo }}" alt="img"></p>
                    </div>
                </div>
                <ul class="info-share org-social d-inline list-inline">
                    <li *ngFor="let link of socailLink" class="list-inline-item">
                        <a href="#"><i class="{{ link.icon }}"></i></a>
                    </li>
                </ul>
                <div class="widget footer-logo text-center">
                    <div class="menu-about-menu-container">
                        <ul id="menu-about-menu" class="menu">
                            <ng-content select="[about_us]"></ng-content>       
                        </ul>
                    </div>
                </div>
                <div class="widget footer-logo text-center">
                    <div class="textwidget">
                        <p>Copyright 2019 sofbox All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- back-to-top -->
    <app-scroll-top></app-scroll-top>
    <!-- back-to-top End -->
</footer>