<p class="rs-p-wp-fix"></p>
<rs-module-wrap id="rev_slider_9_1_wrapper" data-alias="sofbox-9" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
    <rs-module id="rev_slider_9_1" style="display:none;" data-version="6.1.3">
        <rs-slides>
            <rs-slide data-key="rs-3" id="iq-home" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                <img src="./assets/sofbox-v2/revslider/assets/index-9/Untitled-13.png" alt="images" title="Untitled-13.png" width="1600" height="1000" class="rev-slidebg" data-no-retina>
                <!--
                    -->
                <rs-layer
                    id="slider-3-slide-3-layer-0"
                    class="now-font"
                    data-type="text"
                    data-rsp_ch="on"
                    data-xy="x:c;y:m;yo:-347px,-347px,-157px,-156px;"
                    data-text="w:normal;s:45,45,40,26;l:60,60,50,36;a:center;"
                    data-frame_0="y:100%;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:1230;sp:1200;sR:1230;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:6570;"
                    style="z-index:11;font-family:Poppins;"
                    >Biggest Software Landing Website
                </rs-layer>
                <!--
                    -->
                <rs-layer
                    id="slider-3-slide-3-layer-3"
                    data-type="text"
                    data-rsp_ch="on"
                    data-xy="x:c;y:m;yo:-267px,-267px,-88px,-95px;"
                    data-text="w:normal;s:16,16,16,14;l:28,28,30,26;a:center;"
                    data-frame_0="y:100%;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:1850;sp:1200;sR:1850;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:5950;"
                    style="z-index:9;font-family:var(--body-font-family);"
                    >There are many variations of passages of Lorem Ipsum<br>  available, but the majority have suffered
                </rs-layer>
                <!--
                    -->
                <rs-layer
                    id="slider-3-slide-3-layer-4"
                    class="rev-btn"
                    data-type="button"
                    data-color="#a37cfc"
                    data-rsp_ch="on"
                    data-xy="x:c;xo:0,0,-8px,-4px;y:m;yo:-187px,-187px,-16px,-26px;"
                    data-text="w:normal;s:18,18,16,16;l:40,40,25,15;"
                    data-dim="minh:0px,0px,none,none;"
                    data-padding="t:5,5,10,15;r:30,30,20,20;b:5,5,10,15;l:30,30,20,20;"
                    data-border="bor:4px,4px,4px,4px;"
                    data-frame_0="y:100%;"
                    data-frame_0_mask="u:t;"
                    data-frame_1="st:2220;sp:1200;sR:2220;"
                    data-frame_1_mask="u:t;"
                    data-frame_999="o:0;st:w;sR:5580;"
                    data-frame_hover="c:#fff;bgc:#1e1e1e;bor:4px,4px,4px,4px;bri:120%;"
                    style="z-index:8;background-color:#ffffff;font-family:var(--body-font-family);"
                    >Read More
                </rs-layer>
                <!--
                    -->
                <rs-layer
                    id="slider-3-slide-3-layer-5"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="x:c;xo:-3px,-3px,-13px,-4px;y:m;yo:214px,214px,171px,143px;"
                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                    data-dim="w:906px,906px,373px,347px;h:623px,623px,256px,239px;"
                    data-frame_0="sX:0.9;sY:0.9;"
                    data-frame_1="st:2710;sp:1000;sR:2710;"
                    data-frame_999="o:0;st:w;sR:5290;"
                    style="z-index:12;font-family:Roboto;"
                    ><img src="./assets/sofbox-v2/revslider/assets/index-9/Untitled-14.png" alt="images" width="906" height="623" data-no-retina>
                </rs-layer>
                <!--
                    -->
                <rs-layer
                    id="slider-3-slide-3-layer-6"
                    data-type="image"
                    data-rsp_ch="on"
                    data-xy="xo:883px,883px,487px,318px;yo:348px,348px,287px,291px;"
                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                    data-dim="w:157px,157px,98px,60px;h:153px,153px,95px,58px;"
                    data-frame_0="sX:0.9;sY:0.9;"
                    data-frame_1="st:2860;sp:1000;sR:2860;"
                    data-frame_999="o:0;st:w;sR:5140;"
                    data-loop_0="y:-20px;"
                    data-loop_999="y:20px;sp:3000;e:Sine.easeInOut;yym:t;"
                    style="z-index:10;font-family:Roboto;"
                    ><img src="./assets/sofbox-v2/revslider/assets/index-9/56.png" alt="images" width="157" height="153" data-no-retina>
                </rs-layer>
                <!--
                    -->						
            </rs-slide>
        </rs-slides>
        <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
    </rs-module>
    <script>
        if(typeof revslider_showDoubleJqueryError === "undefined") {
        	function revslider_showDoubleJqueryError(sliderID) {
        		var err = "<div class='rs_error_message_box'>";
        			err += "<div class='rs_error_message_oops'>Oops...</div>";
        			err += "<div class='rs_error_message_content'>";
        				err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
        				err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' ->  'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
        				err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
        			err += "</div>";
        	err += "</div>";
        		jQuery(sliderID).show().html(err);
        	}
        }
    </script>
</rs-module-wrap>
