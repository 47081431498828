<!--Client-->
<div class="iq-our-clients white-bg iq-ptb-50">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <app-client-carousel [options]="carousalOption" [carousalClass]="carousalClass" [images]="carousalImages"></app-client-carousel>
      </div>
    </div>
  </div>
</div>
<!--Client end-->
