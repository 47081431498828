import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-style7',
  templateUrl: './card-style7.component.html',
  styleUrls: ['./card-style7.component.css']
})
export class CardStyle7Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
