<div class="text-left iq-breadcrumb-one iq-bg-over">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-12">
                <nav aria-label="breadcrumb" class="text-center iq-breadcrumb-two">
                    <h2 class="title-breadcrumb">{{title}}</h2>
                    <ng-content select="[bannerLinks]"></ng-content>
                </nav>
            </div>
        </div>
    </div>
</div>