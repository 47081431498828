<div id="search-2" class="widget widget_search">
    <form class="search-form">
        <label for="search-form-5e82f753d374f">
            <span class="screen-reader-text">Search for:</span>
        </label>
        <input type="search"  class="search-field mb-5" placeholder="Search" value="" name="s">
        <button type="submit" class="search-submit"><i class="fa fa-search"></i><span class="screen-reader-text">Search</span></button>
    </form>
    <div *ngIf="recentPostList.length > 0" class="post-details">
        <h4 class="mb-4">Recent Post</h4>
        <ul class="iq-post">
            <li *ngFor="let list of recentPostList">
                <div class="post-img">
                    <img src="{{ list.image }}" class="img-fluid" alt="img">
                    <div class="post-blog">
                        <div class="blog-box">
                            <a class="new-link" href="#"><h5>{{ list.title }}</h5></a>
                            <ul class="list-inline">
                                <li class="list-inline-item  mr-3">
                                    <a class="date-widget" href="#"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>{{ list.datetime }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf="categoryList.length > 0" class="categories mt-5">
        <h4 class="mb-3">Categories</h4>
        <ul>
            <li *ngFor="let list of categoryList" class="cat-item">
                <a href="#">{{ list.title }}</a><span class="post_count"> {{ list.total }} </span>
            </li>
        </ul>
    </div>
</div>