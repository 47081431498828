<!-- Header -->
<header id="main-header" [ngClass]="className">
  <ng-content select="[topBar]"></ng-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand appear" href="#">
            <img *ngIf="!changeLogo" class="img-fluid" [id]="styledLogo ? 'logo_img' : ''" src="{{ logoImg }}" alt="#">
            <img *ngIf="changeLogo" class="img-fluid" [id]="styledLogo ? 'logo_img' : ''" src="assets/images/LOGOFIBEXHORIZONTALPOSITIVO.png" alt="#">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="ion-navicon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto w-100 justify-content-end menu">
              <li class="nav-item menu-item" *ngFor="let option of navItemList">
                <a class="nav-link custom_text-white toggledrop" (click)="(!option.is_link ? jumpTo(option.href) : goTo(option.href))">
                  {{ option.title }}
                  <i *ngIf="option.children" class="fa fa-angle-down" aria-hidden="true"></i>
                </a>
                <ul class="sub-menu" *ngIf="option.children" style="display: none;">
                  <li id="menu-item-506" *ngFor="let child of option.child" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-506">
                    <a [routerLink]="child.href" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">{{ child.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ng-content select="[navContent]"></ng-content>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Header End -->
