<section class="sofbox-gradient-bg-top pb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <h2 class="mb-3">How Clients React</h2>
                <div class="owl-carousel our-testimonial" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1">
                    <div *ngFor="let list of List" class="item text-left">
                        <div class="iq-testimonial">
                            <div class="testimonial-info">
                                <div class="testimonial-name mb-3">
                                    <h5 class="d-inline mr-3">{{ list.name }}</h5>
                                    <span class="sub-title">{{ list.design }}</span>
                                </div>
                            </div>
                            <p>{{ list.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-circle postion-relative">
                <div class="testimonial-img">
                    <ul class="list-inline mb-0">
                        <li *ngFor="let list of images" class="list-inline-item">
                            <img class="img-fluid client-img wow zoomIn" src="{{ list.image }}" alt="image0">
                        </li>
                    </ul>
                    <div class="dot-circle">
                        <div class="effect-circle"></div>
                    </div>
                    <div class="main-circle">
                        <div class="circle-bg">
                            <img src="./assets/sofbox-v2/images/home-2/logo-white.png" alt="image">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>