import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-parallax-hero-style1',
  templateUrl: './parallax-hero-style1.component.html',
  styleUrls: ['./parallax-hero-style1.component.css']
})
export class ParallaxHeroStyle1Component implements OnInit, AfterViewInit {

  @Input() ids: string;

  @Input() classNames: string;

  @Input() bgImage: string; // Cambiado a string en lugar de any

  @Input() bgVideo: string; // Agregado input para bgVideo

  @Input() imgDefault: string | undefined;

  @Input() colorDefault: string | undefined;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  onLoadedMetadata(video: HTMLVideoElement) {
    video.play().catch(error => {
      console.error('Error al intentar reproducir el video:', error);
  });

}
}
