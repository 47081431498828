import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [

    //!New routes

    { path: '', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule) },
    { path: 'hogar', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
    { path: 'empresa', loadChildren: () => import('./modules/enterprise/enterprise.module').then(m => m.EnterpriseModule) },
    { path: 'pyme', loadChildren: () => import('./modules/pyme/pyme.module').then(m => m.PymeModule) },
    { path: 'isp', loadChildren: () => import('./modules/isp/isp.module').then(m => m.IspModule) },
    { path: 'clubfibex', loadChildren: () => import('./modules/fibexclub/fibexclub.module').then(m => m.FibexClubModule) },
    { path: 'club-fibex', loadChildren: () => import('./modules/fibexclub/fibexclub.module').then(m => m.FibexClubModule) },
    { path: 'fibexplay', loadChildren: () => import('./modules/fibexplay/fibexplay.module').then(m => m.FibexPlayModule) },
    { path: 'FibexTelecom-Kitdebienvenida', loadChildren: () => import('./modules/pay-methods/pay-methods.module').then(m => m.PayMethodsModule) },
    { path: 'fullturbo', loadChildren: () => import('./modules/fullturbo/fullturbo.module').then(m => m.FullturboModule)},
    { path: 'conecta-empresa', loadChildren: () => import('./modules/conecta-tu-empresa/conecta-tu-empresa.module').then(m => m.ConectaTuEmpresaModule)},
    { path: 'Terminosycondiciones', loadChildren: () => import('./modules/terminosycondiciones/terminos.module').then(m => m.terminosModule)},
    { path: ':client', loadChildren: () => import('./modules/Notitarde/fullturbo2.module').then(m => m.FullturboModule2)},
    { path: 'promo/:client', loadChildren: () => import('./modules/Notitarde2/fullturbo3.module').then(m => m.FullturboModule3)},
    { path: '**', pathMatch: 'full', redirectTo: ''  }
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
