<div id="loading">
    <div id="loading-center">
        <img src="./assets/sofbox-v2/images/loader.gif" alt="loader">
    </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" className="main-header" [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox6 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content">
    <app-features></app-features>
    <app-tips-trick></app-tips-trick>
    <app-tips-trick1></app-tips-trick1>
    <app-portfolio></app-portfolio>

    <app-v-two-testimonial [sectionProp]="testimonialSectionProp">
        <div imageList1 class="bg-img-jump left">
            <div class="wow zoomIn" data-wow-delay="0.2s">
                <img class="img-one" src="./assets/sofbox-v2/images/home-1/21.jpeg" alt="image1">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.4s">
                <img class="img-two" src="./assets/sofbox-v2/images/home-1/22.jpeg" alt="image2">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.6s">
                <img class="img-three" src="./assets/sofbox-v2/images/home-1/23.jpeg" alt="image3">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.8s">
                <img class="img-four" src="./assets/sofbox-v2/images/home-1/24.jpeg" alt="image4">
            </div>
        </div>

        <div testimonialTitle>
            <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
        </div>

        <div testimonialList>
            <app-v-two-testimonial-carousel2 [testimonialList]="testimonialList1"></app-v-two-testimonial-carousel2>
        </div>

        <div imageList2 class="bg-img-jump right">
            <div class="wow zoomIn" data-wow-delay="0.2s">
                <img class="img-one" src="./assets/sofbox-v2/images/home-1/21.jpeg" alt="image1">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.4s">
                <img class="img-two" src="./assets/sofbox-v2/images/home-1/22.jpeg" alt="image2">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.6s">
                <img class="img-three" src="./assets/sofbox-v2/images/home-1/23.jpeg" alt="image3">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.8s">
                <img class="img-four" src="./assets/sofbox-v2/images/home-1/24.jpeg" alt="image4">
            </div>
        </div>
    </app-v-two-testimonial>
    <app-post></app-post>
</div>
<!-- End Main content -->
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle" [footerDescription]="footerDescription">
    <ul menu>
        <li><a class="text-white" href="javascript:void(0)">About Us</a></li>
        <li><a class="text-white" href="javascript:void(0)">Theme</a></li>
        <li><a class="text-white" href="javascript:void(0)">Features</a></li>
        <li><a class="text-white" href="javascript:void(0)">Pricing</a></li>
        <li><a class="text-white" href="javascript:void(0)">Blog</a></li>
    </ul>
    <ul about_us>
        <li><a class="text-white" href="javascript:void(0)">About Sofbox</a></li>
        <li><a class="text-white" href="javascript:void(0)">Roadmap</a></li>
        <li><a class="text-white" href="javascript:void(0)">How it Work</a></li>
        <li><a class="text-white" href="javascript:void(0)">Team</a></li>
        <li><a class="text-white" href="javascript:void(0)">News</a></li>
    </ul>
    <div testimonial>
        <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
    </div>
</app-v-two-footer-style2>