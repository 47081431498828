<section class="sofbox-gradient-bg-top" id="iq-portfolio">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
            <div class="col-lg-12">
                <app-v-two-case-studies [List]="List"></app-v-two-case-studies>
            </div>
        </div>
    </div>
</section>