export const environment = {
    production: false,
    backendThomas: 'https://backend.thomas-talk.me',
    thomas_crm: "https://backend.thomas-talk.me/api/v1",
    clubFibexBD: 'thomas_clubfibex',
    //   clubFibexBD: 'sandbox_clubfibex',
    backendFL: 'https://dbfull.thomas-talk.me/api',
    authToken: "YQ3F2RqZmAeXrYsY3xOd9cdFNOUgR798CRAJdDkWViCsrLctWhINn-p1Lv6BK1zvkhaT-NKzGL04JvXlfvEd3xfnjpvU1LDIefOir1fzyY7_IKbUEENElcbxQL6rrKFmCtW2HSHevbx-HVmrIS6asVk1RX3QK9EQuJ7GyfcfkClakoWjkMtd",
    db: 'thomas_cobertura',
    table: 'tm_Promociones',
    authorizationTKN: 'Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB',
    typeFAI: 'find-any-info',
    field1: 'Visible',
    value1: '1',
    k1: 'T0rNaDoK4tr1Na?RTgcN',
    k2: 'mhKU=',

    UrlFull: 'https://backend.thomas-talk.me/',

  hotspotApi: 'https://hotspot.thomas-talk.me/data-microtik/',
  Apithomas: "https://api.thomas-talk.me/",
  urlApiTls: 'https://apitls.thomas-talk.me',

  // ! Key - Lic -Tokens
  apiKeySsl: '4p1_[51t3m4s]_f186@th0m4s',
  key_enc: 'T0rNaDoK4tr1Na?RTgcNmhKU=',

  // ! Method - Platform - db
  metodoSucursales: 'SucursalesAPP',
  platform: 'Web Fibex',

  //! ChatBot
  botAPI: "https://bot.thomas-talk.me/bot/tell-me",

  //! dbFull

  dbFull: 'https://dbfull.thomas-talk.me/api',
  dbFullCreate: 'create-info',
  dbFullFindAny: 'find-any-info',
  tokenAuthPlatform: 'h34BEVSzAijWRFeXBmRGA8FfarVHVLHZo_mfmS7c7TFUjlpMt0LFiI-NKy1tr89pJwpXoPiL2BemcwuDKIuJzeqii-eiZXilqyQYOcm_o1_SE29K5J76E1G-ZY--m0QHdSXvLrR2-dK6I8S7x24pbyzixoSgldSYCulhmooI5Ls0jAr4HS6a',
  

  //!Club Fibex
  CFdb: 'thomas_clubfibex',
  CFProducts: 'cb_EmpresaProductos',
  //clubFibexBD: 'thomas_sandbox_clubfibex',

  //hubspot
  hubspotUrl:'https://api.hubapi.com/crm/v3/objects/contacts',
  accessTokenHubspot:'pat-na1-0ed19c2e-9919-4e76-8ef3-1abebcb8859d',
  developerApiKeyHubspot:''

};
