import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(private http: HttpClient) { }

  getPromotion(){
    return new Promise<void>((resolve, reject) => {
        try {
            this.http.get('',{

            })
            
        } catch (error) {
            console.error(error)
        }
    })
  }
}
