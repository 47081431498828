<!--=================================
      Header -->
<header id="header" [ngClass]="className">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="index.html">
            <img class="img-fluid" [id]="styledLogo ? 'logo_img' : ''" src="{{ logoImg }}" alt="logo">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ml-auto">
              <ng-container *ngFor="let option of navItemList">
                <li class="nav-item dropdown" *ngIf="option.children; else elseBlock">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ option.title }}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" *ngFor="let child of option.child" [routerLink]="child.href">{{ child.title }}</a>
                  </div>
                </li>
                <ng-template #elseBlock>
                  <li class="nav-item menu-item">
                    <a class="nav-link {{ option._is_active !== undefined && option._is_active ? 'active' : '' }}" (click)="jumpTo(option.href); false" [href]="option.href" *ngIf="!option.is_link;">
                      {{ option.title }}
                    </a>
                    <a class="nav-link {{ option._is_active !== undefined && option._is_active ? 'active' : '' }}" [routerLink]="option.href" *ngIf="option.is_link;">
                      {{ option.title }}
                    </a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </div>
        </nav>
      </div>

    </div>
  </div>
</header>
<!--=================================
Header -->
