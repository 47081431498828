<div class="testimonial-box">
  <p class="description">
    <ng-content select="[cardBody]"></ng-content>
  </p>
  <div class="author">
    <div class="left">
      <ng-content select="[cardMedia]"></ng-content>
    </div>
    <div class="right">
      <ng-content select="[cardFooter]"></ng-content>
    </div>
  </div>
</div>
