import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-card-style2',
  templateUrl: './pricing-card-style2.component.html',
  styleUrls: ['./pricing-card-style2.component.css']
})
export class PricingCardStyle2Component implements OnInit {
@Input() bgImage: string = './assets/images/bg/08.jpg';
  constructor() { }

  ngOnInit() {
  }

}
