<div id="loading">
    <div id="loading-center">
        <img src="./assets/sofbox-v2/images/loader.gif" alt="loader">
    </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" [navItemList]="navItems">
    <li button class="button-btn list-inline-item">
        <nav aria-label="breadcrumb">
            <div class="blue-btn button"><a href="#">Get Started</a></div>
        </nav>
    </li>
</app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox2 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content pt-5">
    <app-platform></app-platform>
    <app-service></app-service>
    <app-about></app-about>
    <app-sales></app-sales>
    <app-features></app-features>
    <app-portfolio></app-portfolio>

    <app-v-two-testimonial [sectionProp]="testimonialSectionProp">
        <div imageList1 class="bg-img-jump left">
            <div class="wow zoomIn" data-wow-delay="0.2s">
                <img class="img-one" src="./assets/sofbox-v2/images/home-1/21.jpeg" alt="image1">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.4s">
                <img class="img-two" src="./assets/sofbox-v2/images/home-1/22.jpeg" alt="image2">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.6s">
                <img class="img-three" src="./assets/sofbox-v2/images/home-1/23.jpeg" alt="image3">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.8s">
                <img class="img-four" src="./assets/sofbox-v2/images/home-1/24.jpeg" alt="image4">
            </div>
        </div>

        <div testimonialTitle>
            <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
        </div>

        <div testimonialList>
            <app-v-two-testimonial-carousel2 [testimonialList]="testimonialList1"></app-v-two-testimonial-carousel2>
        </div>

        <div imageList2 class="bg-img-jump right">
            <div class="wow zoomIn" data-wow-delay="0.2s">
                <img class="img-one" src="./assets/sofbox-v2/images/home-1/21.jpeg" alt="image1">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.4s">
                <img class="img-two" src="./assets/sofbox-v2/images/home-1/22.jpeg" alt="image2">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.6s">
                <img class="img-three" src="./assets/sofbox-v2/images/home-1/23.jpeg" alt="image3">
            </div>
            <div class="wow zoomIn" data-wow-delay="0.8s">
                <img class="img-four" src="./assets/sofbox-v2/images/home-1/24.jpeg" alt="image4">
            </div>
        </div>

    </app-v-two-testimonial>

    <app-post></app-post>

    <app-v-two-contact-map [sectionProp]="contactSectionProp" [contactDetail]="contactDetail">
        <div titleSection>
            <app-v-two-section-title [titleSectionProp]="titleSectionProp2"></app-v-two-section-title>
        </div>
        <ul socailLink  class="share-social list-inline p-0">
            <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fab fa-github"></i></a></li>
            <li class="list-inline-item"><a href="#"><i class="fab fa-instagram"></i></a></li>
        </ul>
        <form contactForm>
            <div class="row">
                <div class="col-lg-6">  <input type="text" class="form-control" id="inputName" placeholder="Your Name"> </div>
                <div class="col-lg-6">   <input type="Email" class="form-control" id="inputEmail" placeholder="Your Email"></div>
                <div class="col-lg-12">  <input type="text" class="form-control" id="inputSubject" placeholder="Your Subject"></div>
                <div class="col-lg-12"> <textarea class="form-control" id="exampleFormControlTextarea1" rows="7" placeholder="Your Message"></textarea>
                </div>
                <div class="col-lg-12">
                    <a class="button blue-btn" href="#">Send Message</a>
                </div>
            </div>
        </form>
    </app-v-two-contact-map>
</div>
<app-v-two-footer-style1 [footerLogo]="footerLogo" [footerText]="footerText">
    <ul menu>
        <li><a href="javascript:void(0)">About Us</a></li>
        <li><a href="javascript:void(0)">Theme</a></li>
        <li><a href="javascript:void(0)">Features</a></li>
        <li><a href="javascript:void(0)">Pricing</a></li>
        <li><a href="javascript:void(0)">Blog</a></li>
    </ul>
    <ul about_us>
        <li><a href="javascript:void(0)">About Sofbox</a></li>
        <li><a href="javascript:void(0)">Roadmap</a></li>
        <li><a href="javascript:void(0)">How it Work</a></li>
        <li><a href="javascript:void(0)">Team</a></li>
        <li><a href="javascript:void(0)">News</a></li>
    </ul>
    <ul quick_link>
        <li><a href="javascript:void(0)">About Us</a></li>
        <li><a href="javascript:void(0)">Theme</a></li>
        <li><a href="javascript:void(0)">Features</a></li>
        <li><a href="javascript:void(0)">Pricing</a></li>
        <li><a href="javascript:void(0)">Blog</a></li>
    </ul>
</app-v-two-footer-style1>
