<section class="popup-images align-left pt-0 pb-4" id="iq-feature">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popup-images align-left  ">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-4/401.png" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
                <div class="layer_wrap iq-objects  style-two left-top">
                    <span class="iq-objects-02">
                        <img src="./assets/sofbox-v2/images/home-1/07.png" alt="drive02">
                    </span>
                    <span class="iq-objects-03">
                        <img src="./assets/sofbox-v2/images/home-1/08.png" alt="drive02">
                    </span>
                </div>
            </div>
            <div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="text-left">
                            <ul class="iq-list wow fadeInUp">
                                <li *ngFor="let service of service1"><i class="fa fa-check"></i><span>{{ service.title }} </span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="text-left">
                            <ul class="iq-list wow fadeInUp">
                                <li *ngFor="let service of service2"><i class="fa fa-check"></i><span>{{ service.title }} </span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="layer_wrap iq-objects  style-one right-top"><span class="iq-objects-01">
                        <img src="./assets/sofbox-v2/images/home-4/400.png" alt="drive02">
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>