<section>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div class="text-left">
                    <ul class="iq-list wow fadeInUp">
                        <li *ngFor="let service of Services"><i class="fa fa-check"></i><span>{{ service.title }} </span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="popup-images align-right">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/700.png" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
            </div>
        </div>
    </div>
</section>