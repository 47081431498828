

<header id="main-header" [ngClass]="className">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" href="index.html">
                        <img class="img-fluid {{ logoClass !== undefined ? logoClass : '' }}" [id]="styledLogo ? 'logo_img' : ''" src="{{ logoImg }}" alt="img">
                        <img class="img-fluid sticky-logo" src="{{ logoWhite }}" *ngIf="logoWhite" alt="img">
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="menu-btn d-inline-block" (click)="toggleClass()" id="menu-btn">
                            <span class="line"></span>
                            <span class="line"></span>
                            <span class="line"></span>
                        </span>
                        <span class="ion-navicon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto w-100 justify-content-end">
                            <ng-container *ngFor="let option of navItemList">
                                <li class="nav-item dropdown" *ngIf="option.children; else elseBlock">
                                    <a class="nav-link dropdown-toggle {{ option._is_active !== undefined && option._is_active ? 'active' : '' }}" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ option.title }}
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" *ngFor="let child of option.child" [routerLink]="child.href" routerLinkActive="active">{{ child.title }} </a>
                                    </div>
                                </li>
                                <ng-template #elseBlock>
                                    <li class="nav-item menu-item">
                                        <a class="nav-link {{ option._is_active !== undefined && option._is_active ? 'active' : '' }}" (click)="jumpTo(option.href); false" [href]="option.href" *ngIf="!option.is_link;">
                                            {{ option.title }}
                                        </a>
                                        <a class="nav-link {{ option._is_active !== undefined && option._is_active ? 'active' : '' }}" [routerLink]="option.href" *ngIf="option.is_link;">
                                            {{ option.title }}
                                        </a>
                                    </li>
                                </ng-template>
                            </ng-container>
                        </ul>
                    </div>
                    <!-- <div class="sub-main">
                        <ul class="shop_list">
                            <li class="search-btn list-inline-item">
                                <div class="search-box">
                                    <form method="get" class="search-form" action="#">
                                        <label for="search-form-5e7adc86eded3">
                                            <span class="screen-reader-text">Search for:</span>
                                        </label>
                                        <input type="search" id="search-form-5e7adc86eded3" class="search-field" placeholder="Search" value="" name="s">
                                        <button type="submit" class="search-submit"><i class="fa fa-search"></i><span class="screen-reader-text">Search</span></button>
                                    </form>
                                </div>
                            </li>
                            <li class="cart-btn list-inline-item">
                                <div class="cart_count">
                                    <a class="parents mini-cart-count" href="#">
                                        <i class="fa fa-shopping-cart"></i>
                                        <div id="mini-cart-count">
                                            0
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <ng-content select="[button]"></ng-content>
                        </ul>
                    </div> -->
                </nav>
            </div>
        </div>
    </div>
</header>