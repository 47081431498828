<section id="iq-pricing" class="pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
        </div>
        <div class="row postion-relative">
            <div class="col-lg-12">
                <div id="pills-tabContent" class="tab-content advance-feature">
                    <div id="pills-0" class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-0">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 border-right-0 btn-top">
                                    <div class="service-price">
                                        <ul>
                                            <li>
                                                <input id="sof_chk_458" class="price-checkbox chk_1" primary="chk_1" value="10" type="checkbox" checked="">
                                                <label for="sof_chk_458"><span>10</span><span class="mr-2">$</span>Feedback System</label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_459" class="price-checkbox chk_1" primary="chk_1" value="20" type="checkbox" checked="">
                                                <label for="sof_chk_459"><span>20</span><span class="mr-2">$</span>Social Integration </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_460" class="price-checkbox chk_1" primary="chk_1" value="40" type="checkbox">
                                                <label for="sof_chk_460"><span>40</span><span class="mr-2">$</span>Push Notifications </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_461" class="price-checkbox chk_1" primary="chk_1" value="80" type="checkbox">
                                                <label for="sof_chk_461"><span>80</span><span class="mr-2">$</span>Relevant App Content </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_462" class="price-checkbox chk_1" primary="chk_1" value="50" type="checkbox">
                                                <label for="sof_chk_462"><span>50</span><span class="mr-2">$</span>Log-in via Social Media </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_463" class="price-checkbox chk_1" primary="chk_1" value="85" type="checkbox">
                                                <label for="sof_chk_463"><span>85</span><span class="mr-2">$</span>Visual Reviews </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_464" class="price-checkbox chk_1" primary="chk_1" value="70" type="checkbox">
                                                <label for="sof_chk_464"><span>70</span><span class="mr-2">$</span>Boost SEO ranking </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_465" class="price-checkbox chk_1" primary="chk_1" value="80" type="checkbox">
                                                <label for="sof_chk_465"><span>80</span><span class="mr-2">$</span>Marketing </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="notes mb-5">
                                        <span>NOTES:</span>
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                    </div>
                                    <form class="form-horizontal">
                                        <div class="form-group">
                                            <div id="slider-container"></div>
                                            <label class="mb-3">Users</label>
                                            <div class="ui segment">
                                                <div class="ui range input-range" id="user_range_1">
                                                    <div class="inner">
                                                        <div class="track"></div>
                                                        <div class="track-fill" style="width: 0px;"></div>
                                                        <div class="thumb" style="left: -10px;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Monthly</label>
                                            <div class="ui segment">
                                                <div class="ui range input-range" id="time_range_1">
                                                    <div class="inner">
                                                        <div class="track"></div>
                                                        <div class="track-fill" style="width: 0px;"></div>
                                                        <div class="thumb" style="left: -10px;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group user-count">
                                            <label class="mb-3">Users</label>
                                            <input type="text" id="user_text_1" class="form-control input-text-user" readonly="" value="1">
                                            <input type="hidden" value="100" id="number_user_1">
                                            <input type="hidden" value="12" id="number_time1">
                                        </div>
                                        <div class="form-group month-count">
                                            <label class="mb-3">Monthly</label>
                                            <input type="text" id="time_text_1" class="form-control input-text-time" readonly="" value="1">
                                        </div>
                                        <div class="form-group total-count">
                                            <label class="mb-3">Total</label>
                                            <input type="text" id="total_1" class="form-control" readonly="" value="30">
                                            <input type="hidden" id="base_1" class="form-control" value="30">
                                        </div>
                                        <div class="text-center">
                                            <a class="button-gradient" href="#">Get started</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="pills-1" class="tab-pane fade  " role="tabpanel" aria-labelledby="pills-home-1">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 border-right-0 btn-top">
                                    <div class="service-price">
                                        <ul>
                                            <li>
                                                <input id="sof_chk_474" class="price-checkbox chk_2" primary="chk_2" value="10" type="checkbox" checked="">
                                                <label for="sof_chk_474"><span>10</span><span class="mr-2">$</span>Feedback System </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_475" class="price-checkbox chk_2" primary="chk_2" value="20" type="checkbox" checked="">
                                                <label for="sof_chk_475"><span>20</span><span class="mr-2">$</span>Social Integration </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_476" class="price-checkbox chk_2" primary="chk_2" value="40" type="checkbox">
                                                <label for="sof_chk_476"><span>40</span><span class="mr-2">$</span>Push Notifications </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_477" class="price-checkbox chk_2" primary="chk_2" value="80" type="checkbox">
                                                <label for="sof_chk_477"><span>80</span><span class="mr-2">$</span>Relevant App Content </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_478" class="price-checkbox chk_2" primary="chk_2" value="50" type="checkbox">
                                                <label for="sof_chk_478"><span>50</span><span class="mr-2">$</span>Log-in via Social Media </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_479" class="price-checkbox chk_2" primary="chk_2" value="85" type="checkbox">
                                                <label for="sof_chk_479"><span>85</span><span class="mr-2">$</span>Visual Reviews </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_480" class="price-checkbox chk_2" primary="chk_2" value="70" type="checkbox">
                                                <label for="sof_chk_480"><span>70</span><span class="mr-2">$</span>Boost SEO ranking </label>
                                            </li>
                                            <li>
                                                <input id="sof_chk_481" class="price-checkbox chk_2" primary="chk_2" value="80" type="checkbox">
                                                <label for="sof_chk_481"><span>80</span><span class="mr-2">$</span>Marketing </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="notes mb-5">
                                        <span>NOTES:</span>
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                    </div>
                                    <form class="form-horizontal">
                                        <div class="form-group">
                                            <div id="slider-container"></div>
                                            <label class="mb-3">Users</label>
                                            <div class="ui segment">
                                                <div id="user_range_2" class="ui range input-range">
                                                    <div class="inner">
                                                        <div class="track"></div>
                                                        <div class="track-fill" style="width: 0px;"></div>
                                                        <div class="thumb" style="left: -10px;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Yearly</label>
                                            <div class="ui segment">
                                                <div id="time_range_2" class="ui range input-range">
                                                    <div class="inner">
                                                        <div class="track"></div>
                                                        <div class="track-fill" style="width: 0px;" ></div>
                                                        <div class="thumb" style="left: -10px;" ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group user-count">
                                            <label class="mb-3">Users</label>
                                            <input id="user_text_2" type="text" class="form-control input-text-user" readonly="" value="1">
                                            <input id="number_user_2" type="hidden" value="1000">
                                            <input id="number_time2" type="hidden" value="12">
                                        </div>
                                        <div class="form-group month-count">
                                            <label class="mb-3">Yearly</label>
                                            <input id="time_text_2" type="text" class="form-control input-text-time" readonly="" value="1">
                                        </div>
                                        <div class="form-group total-count">
                                            <label class="mb-3">Total</label>
                                            <input id="total_2" type="text" class="form-control" readonly="" value="30">
                                            <input id="base_2" type="hidden" class="form-control" value="30">
                                        </div>
                                        <div class="text-center">
                                            <a class="button-gradient" href="#">Get started</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pricing-table">
                        <ul id="pills-tab" class="nav center-btn nav-pills" role="tablist">
                            <li *ngFor="let list of List" class="nav-item">
                                <a class="{{ list.active ? 'nav-link active1 ' : 'nav-link' }}" data-toggle="pill"  href="{{ '#' + list.href }}" role="tab" [attr.aria-selected]=" list.active !== undefined ? true : false " >{{list.title}}</a>
                            </li>
                        </ul>
                        <input id="count-tabs" type="hidden" value="2">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>