<!--Color customizer-->
<div class="iq-customizer" [ngClass]="panel">
    <div class="buy-button"> <a class="opener" (click)="toggle()" href="javascript:void(0)">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <path fill="currentColor"
                    d="M20.04 8.71V4h-4.7L12 .69L8.71 4H4v4.71L.69 12L4 15.34v4.7h4.71L12 23.35l3.34-3.31h4.7v-4.7L23.35 12zM8.83 7.05c.98 0 1.77.79 1.77 1.78a1.77 1.77 0 0 1-1.77 1.77c-.99 0-1.78-.79-1.78-1.77c0-.99.79-1.78 1.78-1.78M15.22 17c-.98 0-1.77-.8-1.77-1.78a1.77 1.77 0 0 1 1.77-1.77c.98 0 1.78.79 1.78 1.77A1.78 1.78 0 0 1 15.22 17m-6.72.03L7 15.53L15.53 7l1.5 1.5z" />
            </svg>
        </a>
    </div>
    <div class="clearfix content-chooser" style="padding: 0;">
        <!-- <h3 class="iq-font-black">Sofbox Awesome Color</h3>
        <p>This color combo available inside whole template. You can change on your wish, Even you can create your own
            with limitless possibilities! </p>
        <ul class="iq-colorChange clearfix">
            <li *ngFor="let color of colors" [ngClass]="(color.selected) ? 'selected' : ''" (click)="styleChange(color)"
                [style.backgroundColor]="color.code">
            </li>
        </ul> -->
        <div class="element-container">
            <img src="{{current}}" alt="promociones" *ngIf="isDefault">
            <video src="{{current}}" *ngIf="!isDefault"></video>
        </div>
    </div>
</div>
<!--Color customizer end-->