<!--Blog-->
<section id="blog" class="overview-block-ptb iq-bg-over  iq-over-blue-80 iq-tool-feature  iq-font-white">
  <div class="soft-about">
    <img class="box-img1 img-fluid wow fadeInUp" alt="" src="./assets/images/device/awesome-img1.png" data-wow-duration="1.5s" style="visibility: visible; animation-duration: 1.5s; animation-name: fadeInUp;">
    <img class="box-img6 img-fluid wow fadeInUp" alt="" src="./assets/images/device/awesome-img6.png" data-wow-duration="2.5s" style="visibility: visible; animation-duration: 2.5s; animation-name: fadeInUp;">
    <img class="box-img3 img-fluid wow rotateIn" alt="" src="./assets/images/device/awesome-img3.png" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: rotateIn;">
    <img class="box-img4 img-fluid wow fadeInRight" alt="" src="./assets/images/device/awesome-img4.png" data-wow-duration="1.5s" style="visibility: visible; animation-duration: 1.5s; animation-name: fadeInRight;">
    <img class="box-img5 img-fluid wow rotateInUpRight" alt="" src="./assets/images/device/awesome-img5.png" data-wow-duration="1.5s" style="visibility: visible; animation-duration: 1.5s; animation-name: rotateInUpRight;">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <h2 class="iq-tw-6 iq-mt-100 iq-mb-20 iq-font-white">How To use Sofbox ?</h2>
        <p class="">Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Simply dummy text of the printing and typesetting industry.</p>
      </div>
      <div class="col-lg-6 col-md-12 soft-about">
      </div>
    </div>
  </div>
</section>
<!--Blog end-->
