<div class="iq-blog text-left iq-ptb-30" [ngClass]="active ? 'active' : ''">
  <div class="text-center">
    <ng-content select="[cardIcon]"></ng-content>
    <ng-content select="[cardTitle]"></ng-content>
  </div>
  <div class="content-blog text-center">
    <ng-content select="[cardBody]"></ng-content>
    <ng-content select="[cardFooter]"></ng-content>
  </div>
</div>
