import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-black-pricing-card-style1',
  templateUrl: './black-pricing-card-style1.component.html',
  styleUrls: ['./black-pricing-card-style1.component.css']
})
export class BlackPricingCardStyle1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
