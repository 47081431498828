<div class="iq-pricing mb-4 mb-lg-0 text-center wow fadeInUp {{ className }}" >
    <div class="price-title iq-parallax">
        <div class="month-circle">
            <ng-content select="[cardHeader]"></ng-content>
        </div>
        <ng-content select="[cardTitle]"></ng-content>
    </div>
    <ng-content select="[cardList]"></ng-content>
    <div class="price-footer">
        <ng-content select="[cardFooter]"></ng-content>
    </div>
</div>
  