<!-- loading -->
<div id="loading">
  <div id="loading-center">
    <div class="loader">
      <div class="cube">
          <img src="assets/video/loader.svg" alt="">
    </div>
  </div>
</div>
<!-- loading End -->
