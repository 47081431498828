

const subHeader = [
  {
    href: '/landing-page1',
    title: 'Landing page 1'
  },
  {
    href: '/landing-page2',
    title: 'Landing page 2'
  },
  {
    href: '/landing-page3',
    title: 'Landing page 3'
  },
  {
    href: '/landing-page4',
    title: 'Landing page 4'
  },
  {
    href: '/landing-page5',
    title: 'Landing page 5'
  },
  {
    href: '/landing-page6',
    title: 'Landing page 6'
  },
  {
    href: '/landing-page7',
    title: 'Landing page 7'
  },
  {
    href: '/landing-page8',
    title: 'Landing page 8'
  },
  {
    href: '/landing-page9',
    title: 'Landing page 9'
  },
  {
    href: '/landing-page10',
    title: 'Landing page 10'
  },
  {
    href: '/landing-page11',
    title: 'Landing page 11'
  }
];


export { subHeader };
