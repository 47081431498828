<app-loader></app-loader>
<!-- Header Component Start-->
<app-header-style1 [logoImg]="navLogo" className="header-one" [navItemList]="navItems">
<app-topbar-style1 topBar>
   <ul leftLinks>
      <li><a href="#"><i class="fa fa-phone iq-mr-10" aria-hidden="true"></i><span class="iq-font-white">+1234567890</span></a></li>
   </ul>
   <ul rightLinks>
      <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
      <li><a href="#"><i class="fab fa-twitter"></i></a></li>
      <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
      <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
   </ul>
</app-topbar-style1>
</app-header-style1>
<!-- Header Component End -->
<!-- Home section start -->
<app-home></app-home>
<!-- Home section end -->
<!-- About section start -->
<app-about></app-about>
<!-- About section End -->
<!-- Feature section start -->
<app-features></app-features>
<!-- Feature section end -->
<!-- Team section start -->
<app-team></app-team>
<!-- Team section end -->
<!-- Pricing section start -->
<app-pricing></app-pricing>
<!-- Feature section end -->
<!-- Blog section start -->
<app-blog></app-blog>
<!-- Blog section end -->
<!-- Client section start -->
<app-clients></app-clients>
<!-- Client section start -->
<!-- Contact section start -->
<app-contact></app-contact>
<!-- Contact section start -->
<!-- Footer component start -->
<app-footer></app-footer>
<!-- Footer component end -->
<!-- Scroll to top component start -->
<app-scroll-top></app-scroll-top>
<!-- Scroll to top component end -->
<!-- Color customizer component start -->
<app-color-customizer></app-color-customizer>
<!-- Color customizer component end -->
