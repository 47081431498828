<div class=" iq-breadcrumb main-bg">
  <div class="container-fluid">
      <div class="row align-items-center">
          <div class="col-lg-6 ">
              <div class="iq-mb-0">
                  <h2 class="text-white">{{title}}</h2>
              </div>
          </div>
          <div class="col-lg-6 ">
              <nav aria-label="breadcrumb" class="float-right">
                  <ol class="">
                    <ng-content select="[bannerLinks]"></ng-content>
                  </ol>
              </nav>
          </div>
      </div>
  </div>
</div>