<section class="pt-0 iq-rpb-zero">
    <div class="container">
        <div class="row align-items-center flex-row-reverse">
            <div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
                <div *ngFor="let list of List" class="fancy-icon wow fadeInUp text-left">
                    <div class="media">
                        <div class="fancy-img">
                            <img src="{{ list.image }}" alt="fancybox">
                        </div>
                        <div class="media-body">
                            <h5 class="mt-0">{{ list.title }}</h5>
                            <p class="mb-0">{{ list.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="popup-images align-left">
                    <div class="img-one wow zoomIn" data-wow-duration="0.6s">
                        <img src="./assets/sofbox-v2/images/home-7/701.png" alt="image1">
                    </div>
                    <div class="d-block"></div>
                </div>
                <div class="layer_wrap iq-objects  style-two left-top"><span class="iq-objects-02">
                    <img src="./assets/sofbox-v2/images/home-7/704.png" alt="drive02">
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>