<div id="loading">
    <div id="loading-center">
        <img src="./assets/sofbox-v2/images/loader.gif" alt="loader">
    </div>
</div>
<!-- Header Component Start-->
<app-v-two-header-style1 [logoImg]="navLogo" className="main-header iq-gradient" [navItemList]="navItems"></app-v-two-header-style1>
<!-- Header Component End-->
<!-- START sofbox6 REVOLUTION SLIDER 6.1.3 -->
<app-slider></app-slider>
<!--End sofbox2 REVOLUTION SLIDER -->
<!-- Main Content -->
<div class="main-content">
    <app-service></app-service>
    <app-service-tab></app-service-tab>
    <app-feature></app-feature>
    <app-case-study></app-case-study>
    <app-pricing></app-pricing>
    <app-post></app-post>
</div>
<!-- End Main content -->
<app-v-two-footer-style2 [footerLogo]="footerLogo" [footerText]="footerText" [footerTitle]="footerTitle" [footerDescription]="footerDescription">
    <ul menu>
        <li><a class="text-white" href="javascript:void(0)">About Us</a></li>
        <li><a class="text-white" href="javascript:void(0)">Theme</a></li>
        <li><a class="text-white" href="javascript:void(0)">Features</a></li>
        <li><a class="text-white" href="javascript:void(0)">Pricing</a></li>
        <li><a class="text-white" href="javascript:void(0)">Blog</a></li>
    </ul>
    <ul about_us>
        <li><a class="text-white" href="javascript:void(0)">About Sofbox</a></li>
        <li><a class="text-white" href="javascript:void(0)">Roadmap</a></li>
        <li><a class="text-white" href="javascript:void(0)">How it Work</a></li>
        <li><a class="text-white" href="javascript:void(0)">Team</a></li>
        <li><a class="text-white" href="javascript:void(0)">News</a></li>
    </ul>
    <div testimonial>
        <app-v-two-testimonial-carousel [testimonialList]="List"></app-v-two-testimonial-carousel>
    </div>
</app-v-two-footer-style2>