<section class="pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <app-v-two-pricing-card-style1 className="blue-hover">
                    <div class="month-price" cardHeader>
                        <h2><small>$</small>29<small></small></h2>
                        <h6>Per Month</h6>
                    </div>
                    <div cardTitle class="main-title">
                        <h2 class="title">Inbox</h2>
                    </div>
                    <ul cardList>
                        <li>Live chat and emails</li>
                        <li>Basic help center</li>
                        <li>Mobile SDK</li>
                        <li><s>600+ Integration</s></li>
                        <li><s>Email,chat and social</s></li>
                        <li><s>Basic help center</s></li>
                    </ul>
                    <a cardFooter class="button blue-btn" href="#">Quickstart</a>
                </app-v-two-pricing-card-style1>
            </div>
            <div class="col-lg-4 col-md-6">
                <app-v-two-pricing-card-style1 className="blue-hover">
                    <div class="month-price" cardHeader>
                        <h2><small>$</small>49<small></small></h2>
                        <h6>Per Month</h6>
                    </div>   
                    <div cardTitle class="main-title">
                        <h2 class="title">Growth</h2>
                    </div>
                    <ul cardList>
                        <li>Live chat and emails</li>
                        <li>Basic help center</li>
                        <li>Mobile SDK</li>
                        <li>600+ Integration</li>
                        <li><s>Email,chat and social</s></li>
                        <li><s>Basic help center</s></li>
                    </ul>
                    <a cardFooter class="button blue-btn" href="#">Quickstart</a>
                </app-v-two-pricing-card-style1>
            </div>
            <div class="col-lg-4 col-md-6">
                <app-v-two-pricing-card-style1 className="blue-hover">
                    <div cardHeader class="month-price">
                        <h2><small>$</small>99<small></small></h2>
                        <h6>Per Month</h6>
                    </div>
                    <div cardTitle class="main-title">
                        <h2 class="title">Scale</h2>
                    </div>
                    <ul cardList>
                        <li>Live chat and emails</li>
                        <li>Basic help center</li>
                        <li>Mobile SDK</li>
                        <li>600+ Integration</li>
                        <li>Email,chat and social</li>
                        <li>Basic help center</li>
                    </ul>
                    <a cardFooter class="button blue-btn" href="#">Quickstart</a>
                </app-v-two-pricing-card-style1>
            </div>
        </div>
    </div>
</section>