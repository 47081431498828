<section class="pt-0 pb-0" id="iq-about">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6">
				<img class="img-fluid" src="./assets/sofbox-v2/images/home-5/508.jpeg" alt="img">
			</div>
			<div class="col-lg-6">
                <app-v-two-section-title [titleSectionProp]="titleSectionProp"></app-v-two-section-title>
				<a class="button blue-btn button-icon mt-5" href="#"><span class="btn-effect">Read More</span></a>
			</div>
		</div>
	</div>
</section>