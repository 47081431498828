<p class="rs-p-wp-fix"></p>
    <rs-module-wrap id="rev_slider_1_1_wrapper" data-alias="sofbox-1" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
		<rs-module id="rev_slider_1_1" style="display:none;" data-version="6.2.1">
		<rs-slides>
		<rs-slide data-key="rs-7" id="iq-home" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
		<img src="./assets/sofbox-v2/revslider/assets/transparent.png" alt="img" class="rev-slidebg" data-no-retina>
		<!--
		--><rs-layer
		id="slider-5-slide-7-layer-1"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:919px,588px,160px,85px;yo:-357px,-228px,360px,251px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:1208px,773px,505px,311px;h:1232px,788px,515px,317px;"
		data-frame_0="sX:0.9;sY:0.9;"
		data-frame_1="sp:1000;"
		data-frame_999="o:0;st:w;sR:8000;"
		style="z-index:8;"
		><img src="./assets/sofbox-v2/revslider/assets/01-7.png" alt="img" width="1208" height="1232" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-2"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:780px,499px,-9px,-16px;yo:41px,26px,259px,188px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:641px,410px,362px,210px;h:582px,372px,328px,191px;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:890;sp:1000;sR:890;"
		data-frame_999="o:0;st:w;sR:7110;"
		style="z-index:18;"
		><img src="./assets/sofbox-v2/revslider/assets/06-2.png" alt="img" width="625" height="567" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-3"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:956px,611px,420px,180px;yo:428px,273px,330px,262px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:498px,318px,270px,148px;h:533px,341px,289px,158px;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:1530;sp:1000;sR:1530;"
		data-frame_999="o:0;st:w;sR:6470;"
		style="z-index:17;"
		><img src="./assets/sofbox-v2/revslider/assets/02-2.png" alt="img" width="591" height="632" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-4"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:188px,120px,91px,56px;yo:102px,65px,49px,30px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:157px,100px,75px,46px;h:365px,233px,177px,109px;"
		data-frame_0="sX:0.9;sY:0.9;"
		data-frame_1="st:1980;sp:1000;sR:1980;"
		data-frame_999="o:0;st:w;sR:6020;"
		style="z-index:16;"
		><img src="./assets/sofbox-v2/revslider/assets/04.png" alt="img" width="157" height="365" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-7"
		data-type="text"
		data-color="#377dff"
		data-rsp_ch="on"
		data-xy="xo:80px,-72px,-82px,-76px;yo:351px,225px,182px,113px;"
		data-text="w:normal;s:16,10,20,12;l:45,28,24,14;a: center;"
		data-dim="w:45px,28px,21px,12px;h:45px,28px,21px,12px;"
		data-padding="t:0,0,10,6;r:0,0,10,6;b:0,0,10,6;l:0,0,10,6;"
		data-border="bor:90px,90px,90px,90px;"
		data-frame_0="sX:0.9;sY:0.9;"
		data-frame_1="st:3030;sp:1000;sR:3030;"
		data-frame_999="o:0;st:w;sR:4970;"
		data-frame_hover="c:#fff;bgc:#377dff;bor:90px,90px,90px,90px;"
		style="z-index:11;background-color:#ebf2ff;font-family:Roboto;cursor:pointer;"
		><i class="fab fa-facebook" aria-hidden="true"></i>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-8"
		data-type="text"
		data-color="#377dff"
		data-rsp_ch="on"
		data-xy="xo:80px,-47px,-115px,-80px;yo:412px,271px,241px,148px;"
		data-text="w:normal;s:16,10,20,12;l:45,28,24,14;a:center;"
		data-dim="w:45px,28px,21px,12px;h:45px,28px,21px,12px;"
		data-padding="t:0,0,10,6;r:0,0,10,6;b:0,0,10,6;l:0,0,10,6;"
		data-border="bor:90px,90px,90px,90px;"
		data-frame_0="sX:0.9;sY:0.9;"
		data-frame_1="st:3010;sp:1000;sR:3010;"
		data-frame_999="o:0;st:w;sR:4990;"
		data-frame_hover="c:#fff;bgc:#377dff;bor:90px,90px,90px,90px;"
		style="z-index:9;background-color:#ebf2ff;font-family:Roboto;cursor:pointer;"
		><i class="fab fa-linkedin" aria-hidden="true"></i>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-9"
		data-type="text"
		data-color="#377dff"
		data-rsp_ch="on"
		data-xy="xo:80px,-56px,-89px,-68px;yo:289px,179px,116px,72px;"
		data-text="w:normal;s:16,10,20,12;l:45,28,24,14;a:center;"
		data-dim="w:45px,28px,21px,12px;h:45px,28px,21px,12px;"
		data-padding="t:0,0,10,6;r:0,0,10,6;b:0,0,10,6;l:0,0,10,6;"
		data-border="bor:90px,90px,90px,90px;"
		data-frame_0="sX:0.9;sY:0.9;"
		data-frame_1="st:3030;sp:1000;sR:3030;"
		data-frame_999="o:0;st:w;sR:4970;"
		data-frame_hover="c:#fff;bgc:#377dff;bor:90px,90px,90px,90px;"
		style="z-index:10;background-color:#ebf2ff;font-family:Roboto;cursor:pointer;"
		><i class="fab fa-twitter" aria-hidden="true"></i>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-10"
		data-type="text"
		data-color="#1e1e1e"
		data-rsp_ch="on"
		data-xy="xo:50px,-66px,-96px,-100px;yo:536px,331px,341px,215px;"
		data-text="w:normal;s:16,10,16,9;l:25,16,24,14;"
		data-btrans="rZ:-90;"
		data-frame_0="sX:0.9;sY:0.9;"
		data-frame_1="st:3060;sp:1000;sR:3060;"
		data-frame_999="o:0;st:w;sR:4940;"
		style="z-index:12;font-family:Poppins;"
		>Follow us on:
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-12"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:1209px,773px,557px,374px;yo:315px,201px,267px,179px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:401px,256px,210px,116px;h:470px,300px,246px,136px;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:2810;sp:1000;sR:2810;"
		data-frame_999="o:0;st:w;sR:5190;"
		style="z-index:13;"
		><img src="./assets/sofbox-v2/revslider/assets/05-2.png" alt="img" width="412" height="483" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-13"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:1258px,805px,640px,283px;yo:-7px,-4px,179px,226px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:382px,244px,193px,144px;h:446px,285px,225px,168px;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:2540;sp:1000;sR:2540;"
		data-frame_999="o:0;st:w;sR:5460;"
		style="z-index:14;"
		><img src="./assets/sofbox-v2/revslider/assets/04-3.png" alt="img" width="414" height="483" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-14"
		data-type="image"
		data-rsp_ch="on"
		data-xy="xo:1259px,805px,239px,94px;yo:691px,442px,390px,307px;"
		data-text="w:normal;s:20,12,9,5;l:0,16,12,7;"
		data-dim="w:380px,243px,184px,96px;h:326px,208px,158px,82px;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:2270;sp:1000;sR:2270;"
		data-frame_999="o:0;st:w;sR:5730;"
		style="z-index:15;"
		><img src="./assets/sofbox-v2/revslider/assets/03-2.png" alt="img" width="482" height="414" data-no-retina>
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-15"
		class="now-font"
		data-type="text"
		data-color="#1e1e1e"
		data-rsp_ch="on"
		data-xy="x:l,l,c,c;xo:184px,117px,0,0;yo:269px,172px,40px,24px;"
		data-text="w:normal;s:60,38,50,30;l:80,51,60,37;a:left,left,center,center;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:1020;sp:1000;sR:1020;"
		data-frame_999="o:0;st:w;sR:6980;"
		style="z-index:19;font-family:var(--title-font-family);"
		>The biggest platform<br />
		for your growth.
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-16"
		data-type="text"
		data-color="#8e989f"
		data-rsp_ch="on"
		data-xy="x:l,l,c,c;xo:190px,121px,0,0;yo:449px,287px,172px,106px;"
		data-text="w:normal;s:16,16,18,16;l:26,26,28,26;a:left,left,center,center;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:1490;sp:1000;sR:1490;"
		data-frame_999="o:0;st:w;sR:6510;"
		style="z-index:20;font-family:var(--body-font-family);"
		>There are many variations of passages of Lorem Ipsum available, <br>but the majority have suffered alteration in some form,
		</rs-layer><!--
		--><rs-layer
		id="slider-5-slide-7-layer-17"
		class="blue-btn button rev-btn"
		data-type="button"
		data-rsp_ch="on"
		data-xy="x:l,l,c,c;xo:186px,119px,0,0;y:t,t,t,m;yo:527px,357px,260px,0;"
		data-text="w:normal;s:16,16,18,16;l:26,26,28,28;a:left,left,center,center;"
		data-dim="minh:0px,none,none,none;"
		data-padding="t:10,10,10,6;r:30,30,30,19;b:10,10,10,6;l:30,30,30,19;"
		data-border="boc:rgba(255,255,255,0.35);bow:1px,1px,1px,1px;bor:4px,4px,4px,4px;"
		data-frame_0="y:50,32,24,14;"
		data-frame_1="st:2060;sp:1000;sR:2060;"
		data-frame_999="o:0;st:w;sR:5940;"
		data-frame_hover="bgc:#1e1e1e;boc:rgba(255,255,255,0.75);bor:4px,4px,4px,4px;bow:1px,1px,1px,1px;bri:120%;"
		style="z-index:21;background-color:#377dff;font-family:var(--body-font-family);"
		>Read More
		</rs-layer><!--
		-->						</rs-slide>
		</rs-slides>
		<rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
		</rs-module>
		<script>
            if(typeof revslider_showDoubleJqueryError === "undefined") {
                function revslider_showDoubleJqueryError(sliderID) {
                    var err = "<div class='rs_error_message_box'>";
                            err += "<div class='rs_error_message_oops'>Oops...</div>";
                            err += "<div class='rs_error_message_content'>";
                            err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
                            err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' -> 'Advanced' -> 'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
                            err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
                            err += "</div>";
                        err += "</div>";
                    jQuery(sliderID).show().html(err);
                }
            }
		</script>
</rs-module-wrap>
