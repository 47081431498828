<section id="how-it-works" class="iq-counter-box-1 overview-block-ptb it-works re4-mt-50">
  <div class="container">
    <div class="row ">
      <div class="col-sm-12">
        <div class="heading-title">
          <h3 class="title iq-tw-7">What is sofbox ?</h3>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col col-lg-4 text-center">
        <div class="iq-counter">
          <div class="counter-date">
            <span class="timer iq-tw-7 iq-font-blue" [CountTo]="5656123" [from]="0" [duration]="5">5656123</span>
            <label class="iq-font-grey">App Download</label>
          </div>
        </div>
      </div>
      <div class="col-md-auto text-center r4-mt-30">
        <div class="iq-counter">
          <div class="counter-date">
            <span class="timer iq-tw-7 iq-font-blue" [CountTo]="2530" [from]="0" [duration]="5">2530</span>
            <label class="iq-font-grey">Happy Clients</label>
          </div>
        </div>
      </div>
      <div class="col col-lg-4 text-center r4-mt-30">
        <div class="iq-counter">
          <div class="counter-date">
            <span class="timer iq-tw-7 iq-font-blue" [CountTo]="8120" [from]="0" [duration]="5">8120</span>
            <label class="iq-font-grey">Active Accounts</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
