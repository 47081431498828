<!-- Header -->
<header id="main-header" [ngClass]="className">
  <ng-content select="[topBar]"></ng-content>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="#">
            <img class="img-fluid" [id]="styledLogo ? 'logo_img' : ''" src="{{ logoImg }}" alt="#">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="ion-navicon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto justify-content-end header-style2 menu">
              <li class="nav-item menu-item" *ngFor="let option of navItemList">
                <a class="nav-link {{ option._is_active !== undefined && option._is_active ? 'active' : '' }}" (click)="jumpTo(option.href); false" href="{{ option.href}}">
                  {{ option.title }}
                  <i *ngIf="option.children" class="fa fa-angle-down toggledrop" aria-hidden="true"></i>
                </a>
                <ul class="sub-menu border-radius-4" *ngIf="option.children" style="display: none;">
                  <li id="menu-item-506" *ngFor="let child of option.child" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-506">
                    <a [routerLink]="child.href" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">{{ child.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ng-content select="[navContent]"></ng-content>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Header End -->

