<div class="iq-team grey-bg">
  <div class="iq-team-img">
    <ng-content select="[cardMedia]"></ng-content>
  </div>
  <div class="iq-team-info text-center">
    <ng-content select="[cardHeader]"></ng-content>
  </div>
  <div class="share">
    <ng-content select="[cardOverlay]"></ng-content>
  </div>
</div>
