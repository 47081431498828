<div class="iq-fancy-boxnew text-center">
  <div class="">
    <i *ngIf="!source" aria-hidden="true" class="{{ icon }}"></i>
    <img *ngIf="!icon" src="{{source}}" alt="">
  </div>
  <div class="fancy-content">
    <h5 class="iq-tw-7 iq-pt-20 iq-pb-10">{{ title }}</h5>
    <p>{{ text }}</p>
  </div>
</div>
