import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-style4',
  templateUrl: './card-style4.component.html',
  styleUrls: ['./card-style4.component.css']
})
export class CardStyle4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
