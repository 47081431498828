<!--Software feature-->
<section id="software-features" class="life-work overview-block-ptb how-works">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="heading-title left text-left">
          <h3 class="iq-tw-7 iq-mb-25 title">What sofbox can do ?</h3>
        </div>
        <p class="">Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
        <p class="">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
        <h6 class="iq-tw-6 mb-3">Discover our best ever services.</h6>
        <a href="javascript:void(0)" class="button">Click Here</a>
      </div>
      <div class="iq-software-demo-1">
        <img class="img-fluid" src="./assets/images/drive/15.jpg" alt="drive05">
      </div>
    </div>
  </div>
</section>
<!--Software feature end-->
